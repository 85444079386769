<template>
  <callback-panel
    :title="title"
    :subTitle="subTitle"
    :message="message"
    :type="type"
    :redirect-to="redirectTo"
    :button-title="buttonTitle"
  />
</template>

<script>
import CallbackPanel from "../layouts/components/CallbackPanel.vue";

export default {
  components: {
    CallbackPanel,
  },
  data() {
    const {
      title = "",
      subTitle = "",
      message = "Unknown message.",
      type = "success",
      redirectTo = "/",
      buttonTitle = "Ok",
    } = this.$route.query;
    return {
      title,
      subTitle,
      message,
      type,
      redirectTo,
      buttonTitle,
    };
  },
};
</script>

<style scoped></style>
